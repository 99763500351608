<template>
  <b-card>
    <header-table title="تدريبات تراثية" @create="onAdd" />
    <div class="border pt-1 px-1 rounded-lg mb-1 shadow-sm">
      <b-row>
        <b-col cols="5">
          <form-input label="" v-model="filter" placeholder="ابحث هنا..." class="w-50" />
        </b-col>
      </b-row>
    </div>
    <data-table
      ref="estatesTable"
      :fields="fields"
      :filter.sync="filter"
      ep="internal-activities/heritage_trainings"
      :utils.sync="utils"
    >
      <template #cell(actions)="row">
        <div class="d-flex">
          <b-button
            variant="flat-info"
            title="عرض المزيد من المعلومات"
            size="sm"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            class=""
            v-b-tooltip.hover.v-info.top
            @click="row.toggleDetails"
            v-b-toggle.collapse-1
          >
            المزيد
            <feather-icon
              :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            />
          </b-button>
          <actions-table
            @delete="onDelete(row.item)"
            @update="onUpdateClicked(row.item)"
          />
        </div>
      </template>
      <template #row-details="row">
        <b-collapse id="collapse-1" class="mt-2">
          <content-activite :row="row"></content-activite>
        </b-collapse>
      </template>
    </data-table>
    <form-modal
      ref="estateModalActivite"
      :formSchema="estatesFormSchema"
      title="إضافة"
      v-model="activModel"
      :loading="loading"
      @confirm="onModalConfirmed"
    >
      <template #cost="item">
        <form-mask
          v-if="item.form.is_payment"
          label="الكلفة"
          options="number"
          rules="required"
          dir="ltr"
          placeholder="1,000"
          v-model="item.form.cost"
        />
      </template>
      <template #payment_method="item">
        <form-select
          v-if="item.form.is_payment"
          v-model="item.form.payment_method"
          id="payment_method"
          label="طريقة الدفع"
          rules="required"
          :reduce="(el) => el.value"
          :options="paymentMethod"
        />
      </template>
      <template #reservation_information="item">
        <form-textarea
          v-if="item.form.is_reservation"
          label="تفاصيل الحجز"
          rules="required"
          v-model="item.form.reservation_information"
        />
      </template>
      <template #images>
        <form-file
          label="صور عن التدريب "
          accept="image/*"
          id="form-file"
          name="image"
          placeholder="لم تقم باختيار صور"
          multiple
          @change="previewImage"
        />
        <div class="text-center mb-1">
          <layout-input-image
            v-for="(item, id) in staticImages"
            :key="id"
            :url="item.url"
            :name="item.name"
            :is_last_imge="false"
            @deleteItem="deleteImage(item)"
          >
          </layout-input-image>
          <layout-input-image
            v-for="(item, id) in deleted_last_images"
            :key="id"
            :url="item.url"
            :is_last_imge="true"
            @deleteItem="deleteLastImage(item)"
          >
          </layout-input-image>
        </div>
      </template>
    </form-modal>
  </b-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  BCard,
  BButton,
  BRow,
  BCol,
  VBToggle,
  VBTooltip,
  BCollapse,
} from "bootstrap-vue";
import DataTable from "@/components/data-table/index";
import FormInput from "@/components/form-input/index";
import Ripple from "vue-ripple-directive";
import FormModal from "@/components/FormModal.vue";
import FormFile from "@/components/form-file/index.vue";
import FormTextarea from "@/components/form-textarea";
import FormMask from "@/components/form-mask/index.vue";
import FormSelect from "@/components/form-select/index.vue";
import IconMedia from "@/components/icon-media.vue";
import LayoutInputImage from "@/components/LayoutInputImage.vue";
import ContentActivite from "../../../component/ContentActivite.vue";
import ActionsTable from "@/components/data-table/components/actions-table.vue";
import HeaderTable from "@/components/data-table/components/header-table.vue";
import dataSchema from "../mixinsOuthe";
import * as ep from "../../../endpoints";

export default {
  components: {
    FormTextarea,
    HeaderTable,
    ActionsTable,
    IconMedia,
    FormMask,
    FormSelect,
    BCard,
    DataTable,
    BButton,
    FormInput,
    BRow,
    BCol,
    FormModal,
    FormFile,
    BCollapse,
    VBTooltip,
    VBToggle,
    LayoutInputImage,
    ContentActivite,
  },
  data: () => {
    return {
      imageSlide: [],
      staticImages: [],
      deleted_images: [],
      deleted_last_images: [],
      activModel: false,
      filter: "",
      utils: {
        status: "",
      },
    };
  },
  mixins: [dataSchema],
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  methods: {
    ...mapActions("manageApp/activity", ["update", "create", "delete"]),

    deleteImage(item) {
      this.imageSlide = this.imageSlide.filter((file) => file.name !== item.name);
      this.staticImages = this.staticImages.filter((file) => file.name !== item.name);
    },
    deleteLastImage(item) {
      this.deleted_images.push(item.id);
      this.deleted_last_images = this.deleted_last_images.filter(
        (data) => data.id != item.id
      );
    },
    onAdd() {
      this.$refs.estateModalActivite.init({});
      this.imageSlide = [];
      this.staticImages = [];
      this.deleted_images = [];
      this.deleted_last_images = [];
      this.activModel = true;
    },
    previewImage(e) {
      this.imageSlide.push(...e.target.files)
      this.staticImages.push(...Array.from(e.target.files).map((file) => ({
        url: URL.createObjectURL(file),
        name: file.name,
      })))
    },
    onUpdateClicked(form) {
      var toUpdate = {
        ...form,
        reservation_information: form.reservation_information
          ? form.reservation_information
          : " ",
        filter: "",
      };
      this.deleted_last_images = form.images;
      this.$refs.estateModalActivite.init(toUpdate);
      this.activModel = true;
      this.imageSlide = [];
      this.staticImages = [];
      this.deleted_images = [];
    },

    onDelete(item) {
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف ", {
          title: "تأكيد حذف ",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.delete({ ep: ep.TRAINING_HERITAGE_BY_ID(item.id) }).then(() => {
              this.$refs.estatesTable.refreshTable();
            });
        });
    },

    onModalConfirmed(form) {
      var fd = new FormData();
      for (const key in form) {
        if (Object.hasOwnProperty.call(form, key)) {
          const element = form[key];
          if (form[key] != null && form[key].length != 0 && key != "images") {
            if (key == "cost" || key == "max_member" || key == "number_per_user")
              fd.append(key, element.replace(/\D/g, ""));
            else fd.append(key, element);
          }
        }
      }
      if (this.imageSlide.length != 0)
        for (let i = 0; i <= this.imageSlide.length; i++) {
          fd.append("images[]", this.imageSlide[i]);
        }
      if (this.deleted_images.length !== 0)
        for (let i = 0; i < this.deleted_images.length; i++) {
          fd.append("deleted_images[]", this.deleted_images[i]);
        }
      if (form.id) {
        fd.append("_method", "PUT");
        this.update({ ep: ep.TRAINING_HERITAGE_BY_ID(form.id), fd: fd }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
      } else
        this.create({ fd: fd, ep: ep.TRAINING_HERITAGE }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
    },
  },

  computed: {
    ...mapGetters("manageApp/activity", ["loading"]),
  },
};
</script>
