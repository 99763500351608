<template>
  <div 
  :class="!is_last_imge ? 'shadow shadow-success shadow-intensity-lg border-success':''"
  class=" d-flex justify-content-between shadow shadow-intensity-lg align-items-center my-1 border rounded-lg ">
    <b-img
      :src="url"
      fluid-grow
      style="width: 50px !important; height: 50px"
      class="m-1 rounded"
    />
    <span>
      {{ name }}
    </span>
    <b-button
      v-show="is_delete"
      variant="flat-danger"
      class="btn-icon rounded-circle"
      @click="$emit('deleteItem')"
    >
      <feather-icon size="15" icon="Trash2Icon" />
    </b-button>
  </div>
</template>
<script>
import { BButton, BImg } from "bootstrap-vue";

export default {
  props: {
    name: {
      type: String,
      default: () => "",
    },
    url: {
      type: String,
      default: () => "",
    },
    is_last_imge:{
      type:Boolean
    },
    is_delete:{
      type:Boolean,
      default: () => true,
    }
  },
  components: {
    BButton,
    BImg,

  },
};
</script>