export const typesHalls=[
    { label: "تدريبية", value: "T" },
    { label: "مساحة عمل", value: "W" },
    { label: "مكتب", value: "M" },
    { label: "استوديو التسجيل", value: "S" },
]

export const floors=[
    { label: "الطابق الأول", value: 0 },
    { label: "الطابق الأرضي", value: 1 },
]

export const YesOrNo=[
    { label: "نعم", value: 1 },
    { label: "لا", value: 0 },
]

export const paymentMethod = [
    { label: 'إلكتروني', value: 'E' },
    { label: 'كاش', value: 'C' },
]

export const LocationAleppo ={
    lng: "37.16204839923488",
    lat: "36.19846891224607" ,
};
